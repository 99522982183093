import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import SEO from '../components/SEO';

import { ContactPage } from '../imageURLs';

import CtaCard from '../components/CtaCard/CtaCard';
import TextBlock from '../components/TextBlock';
import { CenterStyle } from '../theme';

import { withQuoteWizard } from '../components/withQuoteWizard';

const styles = theme =>
  createStyles({
    root: {
      backgroundColor: '#fff',
      [theme.breakpoints.up(960)]: {
        paddingTop: 30,
      },
    },
    contentInnerWrap: {
      ...CenterStyle
    },
    tableDayColumn: {
      // textAlign: 'right',
      textAlign: 'center',
      paddingRight: 7,
    },
    tableTimeColumn: {
      textAlign: 'left',
      paddingLeft: 7,
    },
    visitUsText: {
      textAlign: 'center',
    },
  });

const contactTimeFrames = [
  {
    day: 'Mon to Thu',
    time: '7am – 5.00pm',
  },
  {
    day: 'Fri',
    time: '7am – 4.30pm',
  },
  {
    day: 'Sat',
    time: '8am – 12.00pm',
  },
];

const ThePage = ({ data, classes, children }) => (
  <React.Fragment>
    <SEO
      title="Contact Twin View Turf - Producer of Australia’s Best Lawns"
      keywords={[
        `Turf`,
        `Lawns`,
        `Best Lawns`,
        `Australia’s Best Lawns`,
        `lawn producer`,
      ]}
    />

    <TextBlock text="Contact us" />

    <Grid container direction="row" className={classes.root}>
      <Grid container direction="row" className={classes.contentInnerWrap}>
        <CtaCard
          image={ContactPage.callUs}
          header="Call us"
          externalLink={true}
          ctaHref="tel:1800686711"
          ctaText="1800 686 711"
          md={4}
        >
          <table>
            <tbody>
              {contactTimeFrames.map(tf => (
                <tr key={tf.day}>
                  <td className={classes.tableDayColumn}>
                    <Typography variant="body1" component="p">
                      {tf.day}
                    </Typography>
                  </td>
                  <td className={classes.tableTimeColumn}>
                    <Typography variant="body1" component="p">
                      {tf.time}
                    </Typography>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CtaCard>

        <CtaCard
          image={ContactPage.visitUs}
          header="Our office"
          text={[
            'Due to current COVID-19 restrictions, our office is closed.',
            'For more information please call us.',
          ]}
          externalLink={true}
          ctaHref="tel:1800686711"
          ctaText="1800 686 711"
          md={4}

        />

        <CtaCard
          image={ContactPage.emailUs}
          header="Email us"
          text={[
            'Send your queries to our sales inbox and we’ll be in touch real soon:',
            'sales@twinviewturf.com.au',
          ]}
          ctaHref="mailto:info@twinviewturf.com.au"
          ctaText="Send email"
          externalLink={true}
          md={4}

        />
      </Grid>
    </Grid>

    {children}
  </React.Fragment>
);

export default withQuoteWizard(
  withStyles(styles, { withTheme: true })(ThePage)
);
